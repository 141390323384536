@mixin flex($a:'unset', $j:'unset', $d:'unset', $w:'unset') {
  display: flex;
  @if ($a != 'unset') {
    align-items: $a;
  }
  @if ($j != 'unset') {
    justify-content: $j;
  }
  @if ($d != 'unset') {
    flex-direction: $d;
  }
  @if ($w!='unset') {
    flex-wrap: $w;
  }
}

@mixin notLastChild {
  & > :not(:last-child) {
    @content;
  }
}

@mixin lineClamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
