@import './helpers';

@mixin checkbox {
  flex: 0 0 auto;
  position: relative;
  z-index: 0;
  width: 24px;
  height: 24px;
  background: white;
  appearance: none;
  border-radius: 4px;

  &::before {
    @include flex(center, center);
    position: absolute;
    z-index: 1;
    transition: opacity 0.25s;
    opacity: 0;
    background: #0f7ae5;
    content: '\2714';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    border-radius: 4px;
  }

  &:checked {
    &::before {
      opacity: 1;
    }
  }
}

@mixin scrollbar {
  @media screen and (any-hover: hover) {
    &::-webkit-scrollbar {
      width: 9px
    }
    &::-webkit-scrollbar-track {
      background: #e2e4eb;
    }
    &::-webkit-scrollbar-thumb {
      background: black;
      border: 3px solid #e2e4eb;
    }
  }
}
