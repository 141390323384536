@import 'src/styles/shared';

label {
  cursor: pointer;
}

html body .main-form {
  &-label {
    @include flex;
    font-size: 14px;
    margin: 30px 0 0;

    a {
      display: inline;
      transition: color 0.3s;
      color: #0f7ae5;

      &:hover {
        text-decoration: underline;
        color: #147be6;
      }
    }
  }

  &-checkbox {
    @include checkbox;
    margin: 0 10px 0 0;
    padding: 0;
  }
}

html .popup {
  @include scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0;
  background: #e2e4eb;

  &__close {
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
  }

  &__input {
    border: 1px solid transparent;
  }

  .main-form-label {
    font-size: 13px;
    margin: 15px 0;
  }
}
